// auth.js

import { reactive } from "vue";
import  axios  from "@/utils/axios";
// Create a reactive object to store authentication state
const authState = reactive({
  isLoggedIn: false,
});

const userId = reactive({
  id: null,
});

const user = reactive({
  user: null,
});

// Function to check if a user is logged in
const checkLoggedIn = async () => {
  try {
    const response = await axios.get("/accounts/users/me/");
    authState.isLoggedIn = response.status === 200;
    userId.id = response.data.id;
    user.user = response.data;
  } catch (error) {
    authState.isLoggedIn = false;
  }
};

// Export the reactive authentication state and the checkLoggedIn function
export { authState, checkLoggedIn , userId,user};
