<template>
    <div class="DropDown" ref="dropdown" :aria-expanded="isOpen"> <!-- ARIA for accessibility -->
        <!-- Trigger button slot -->
        <slot name="trigger" :toggleDropdown="toggleDropdown"></slot>

        <!-- Dropdown content slot -->
        <transition name="fade">
            <div v-show="isOpen" class="dropdown-content" @click.stop tabindex="-1">
                <!-- Prevent tabbing inside the dropdown closing it -->
                <slot name="content"></slot>
            </div>
        </transition>
    </div>
</template>
<script>
    import { ref, onMounted, onBeforeUnmount } from 'vue';

    export default {
        name: 'DropDown',
        setup() {
            const isOpen = ref(false);
            const dropdown = ref(null);

            const toggleDropdown = () => {
                isOpen.value = !isOpen.value;
            };

            const closeDropdown = () => {
                isOpen.value = false;
            };

            const handleClickOutside = (event) => {
                if (dropdown.value && !dropdown.value.contains(event.target)) {
                    closeDropdown();
                }
            };

            onMounted(() => {
                document.addEventListener('click', handleClickOutside);
            });

            onBeforeUnmount(() => {
                document.removeEventListener('click', handleClickOutside);
            });

            return {
                isOpen,
                toggleDropdown,
                closeDropdown,
                dropdown,
            };
        },
    };
</script>

<style scoped>
    .DropDown {
        position: relative;
        display: inline-block;
    }

    .dropdown-trigger {
        display: inline-block;
        padding: 10px 20px;
        background-color: #007bff;
        color: #fff;
        border: none;
        border-radius: 4px;
        cursor: pointer;
        font-size: 16px;
        font-weight: 500;
        transition: background-color 0.3s ease, transform 0.3s ease;
    }

    .dropdown-trigger:hover {
        background-color: #0056b3;
        transform: translateY(-2px);
    }

    .dropdown-content {
        display: none;
        position: absolute;
        top: 100%;
        left: 0;
        background-color: #fff;
        box-shadow: 0px 8px 16px rgba(0, 0, 0, 0.2);
        border-radius: 4px;
        padding: 10px 0;
        margin-top: 8px;
        z-index: 1000;
        min-width: 200px;
        font-size: 14px;
        list-style: none;
        justify-content: flex-start;
        text-align: left;
        animation: fadeIn 0.3s ease;
    }

    .DropDown[aria-expanded="true"] .dropdown-content {
        display: block;
    }

    .dropdown-content a {
        display: block;
        padding: 10px 20px;
        color: #333;
        text-decoration: none;
        transition: background-color 0.3s ease, color 0.3s ease;
    }

    .dropdown-content a:hover {
        background-color: #f1f1f1;
        color: #007bff;
    }

    .dropdown-content a:focus {
        background-color: #e9ecef;
        outline: none;
        color: #0056b3;
    }

    .fade-enter-active,
    .fade-leave-active {
        transition: opacity 0.3s ease;
    }

    .fade-enter,
    .fade-leave-to {
        opacity: 0;
    }

    @keyframes fadeIn {
        from {
            opacity: 0;
            transform: translateY(10px);
        }

        to {
            opacity: 1;
            transform: translateY(0);
        }
    }

    .dropdown-content::-webkit-scrollbar {
        width: 6px;
    }

    .dropdown-content::-webkit-scrollbar-thumb {
        background-color: rgba(0, 0, 0, 0.3);
        border-radius: 3px;
    }

    .dropdown-content::-webkit-scrollbar-thumb:hover {
        background-color: rgba(0, 0, 0, 0.5);
    }

    .dropdown-trigger::-webkit-scrollbar {
        width: 6px;
    }

    .dropdown-trigger::-webkit-scrollbar-thumb {
        background-color: rgba(0, 0, 0, 0.3);
        border-radius: 3px;
    }

    .dropdown-trigger::-webkit-scrollbar-thumb:hover {
        background-color: rgba(0, 0, 0, 0.5);
    }
</style>


