// Features.js


const collaboration = require("../../assets/solutions/collaboration.png");
const jobsportal = require("../../assets/solutions/JobsPortal.png");
const materials = require("../../assets/solutions/Materials-Sourcing.png");
const property = require("../../assets/solutions/Property-Search.png");
const financing = require("../../assets/solutions/Funding.png");
const Property_Search = require("../../assets/solutions/Property-Search.jpg");
const virtual_tour = require("../../assets/solutions/virtual-tour.mp4");
const morgagecalculator = require("../../assets/solutions/morgagecalculator.jpg");
const Rentalyield = require("../../assets/solutions/Rentalyield.jpg");
const property_maps = require("../../assets/solutions/property_maps.jpg");
const tenantScreening = require("../../assets/solutions/JobsPortal.png");

const maintenance = require("../../assets/solutions/JobsPortal.png");


const financialStatements = require("../../assets/solutions/JobsPortal.png");


const communication = require("../../assets/solutions/JobsPortal.png");


export const features = [
  {
    title: "Unified Collaboration Hub",
    description:
      "Centralized platform for seamless interaction and collaboration among professionals.",
    details: [
      "Enables real-time communication, file sharing, and project management.",
      "Facilitates cross-disciplinary collaboration and knowledge sharing.",
    ],
    image: collaboration,
    CallToAction: "Connect & Build Smarter!",
    route: "/",
  },
  {
    title: "Comprehensive Job Portal",
    description:
      "Streamlines the search for new job opportunities within the Built Environment Value Chain.",
    details: [
      "Provides a user-friendly interface for job posting, application, and tracking.",
      "Matches professionals with suitable job engagements based on their skills and preferences.",
    ],
    image: jobsportal,
    CallToAction: "Get Started",
    route: "/jobs",
  },
  {
    title: "Materials Database",
    description:
      "Offers a vast collection of materials with real-time pricing information.",
    details: [
      "Simplifies the sourcing process for professionals by providing detailed specifications, reviews, and comparisons.",
      "Helps professionals make informed decisions by offering insights into market prices and trends.",
    ],
    image: materials,
    CallToAction: "Get Started",
    route: "/materials",
  },
  {
    title: "Property Search Engine",
    description:
      "Enhances the property discovery process for individuals seeking properties to purchase or lease.",
    details: [
      "Provides advanced search functionality with filters for location, price range, amenities, etc.",
      "Aggregates listings from various sources to offer a comprehensive database of available properties.",
    ],
    image: property,
    CallToAction: "Get Started",
    route: "/property",
  },
  {
    title: "Financing Access Center",
    description:
      "Simplifies access to financing options for both professionals and individuals within the Built Environment Value Chain.",
    details: [
      "Curates a list of financing options including loans, grants, and investment opportunities tailored to the needs of users.",
      "Streamlines the application process and provides guidance on securing funding for projects or property purchases.",
    ],
    image: financing,
    CallToAction: "Get Started",
    route: "/",
  },
];

export const PropertyFeatures = [
  {
    title: "Find Your Perfect Place - Fast!",
    description:
      "Search nearby listings or explore specific areas with our convenient geolocation search.",
    details: [
      "Use your GPS to find properties close by.",
      "Visualize property locations on maps and assess neighborhoods and amenities.",
    ],
    image: property_maps,
    CallToAction: "Get Started",
    route: "/buy",
  },
  {
    title: "Step Inside Virtually!",
    description:
      "Explore properties with immersive 360° tours and interactive floor plans before scheduling a visit.",
    details: [
      "Experience properties from anywhere with 360-degree video tours.",
      "Customize your exploration with interactive floor plans.",
    ],
    image: virtual_tour,
    CallToAction: "Get Started",
    route: "/buy",
  },
  {
    title: "Make Smarter Decisions",
    description:
      "Analyze potential rental income, property value growth, and overall return on investment with our investment analysis tools.",
    details: [
      "Calculate potential rental yields and capital appreciation.",
      "Gain insights with market analysis data to compare similar properties.",
    ],
    image: Rentalyield,
    CallToAction: "Get Started",
    route: "/buy",
  },
  {
    title: "Stay Informed - Market Trends at Your Fingertips",
    description:
      "Get up-to-date insights into real estate market trends and forecasts with our comprehensive reports.",
    details: [
      "Access reports detailing current market conditions, historical data, and future projections.",
      "Make data-driven decisions with a clear picture of the market.",
    ],
    image: morgagecalculator,
    CallToAction: "Get Started",
    route: "/subscribe",
  },
  {
    title: "Refine Your Search with Ease",
    description:
      "Find properties that match your needs exactly with our advanced filtering options.",
    details: [
      "Filter by property type, price range, number of bedrooms, and more.",
      "Save and customize your search preferences for future use.",
    ],
    image: Property_Search,
    CallToAction: "Get Started",
    route: "/property",
  },
];

export const JobFeatures = [
  {
    title: "Job Posting Portal",
    description:
      "Enables employers to post job vacancies quickly and efficiently.",
    details: [
      "Streamlined job posting interface designed for construction industry needs.",
      "Supports bulk postings and template-based entries to save time and ensure consistency.",
    ],
    image: jobsportal,
    CallToAction: "Get Started",
    route: "/myprofile?value=hire-manpower",
  },
  {
    title: "Advanced Job Search and Filters",
    description:
      "Robust search tools allow candidates to filter job opportunities by various criteria.",
    details: [
      "Filters include job title, location, required skills, project duration, and more.",
      "Features advanced algorithms to match construction professionals with suitable job openings.",
    ],
    image: jobsportal,
    CallToAction: "Get Started",
    route: "/jobsearch",
  },
  {
    title: "Easy Application Process",
    description:
      "Simplifies the application process, enabling quick submissions directly through the platform.",
    details: [
      "Auto-fills application forms with profile data to speed up the process.",
      "Supports uploading certifications and previous project portfolios.",
    ],
    image: jobsportal,
    CallToAction: "Get Started",
    route: "/jobsearch",
  },
  {
    title: "Pre-vetting and Skill Verification",
    description:
      "Ensures that all candidates meet job requirements through a pre-vetting process.",
    details: [
      "Incorporates skill verification checks and references as part of the application process.",
      "Helps employers find qualified candidates by verifying skills and previous job performance.",
    ],
    image: jobsportal,
    CallToAction: "Get Started",
    route: "/myprofile?value=my-profile",
  },
  {
    title: "Rating System",
    description:
      "Features a rating system for both employers and candidates to foster transparency.",
    details: [
      "Allows users to rate each other after job completion to build a reputable profile.",
      "Helps improve job matching by using peer reviews and feedback.",
    ],
    image: jobsportal,
    CallToAction: "Get Started",
    route: "/jobs",
  },
  {
    title: "Networking Opportunities",
    description:
      "Connects construction professionals through online and offline networking events.",
    details: [
      "Organizes industry-specific events and webinars to foster connections and collaboration.",
      "Provides a community platform for professionals to share insights, exchange ideas, and explore new opportunities.",
    ],
    image: jobsportal,
    CallToAction: "Get Started",
    route: "/myprofile?value=consultants",
  },
];

export const FinancingFeatures = [
  {
    title: "Find the Perfect Loan - Easily",
    description:
      "Compare financing options side-by-side to find the best rates and terms for your project.",
    details: [
      "Get detailed breakdowns of interest rates, repayment terms, and fees.",
      "Filter and sort options to match your specific financial situation and preferences.",
    ],
    image: "loan_comparison",
    CallToAction: "Get Started",
    route: "/funding",
  },

  {
    title: "Connect with Potential Investors",
    description:
      "Expand your funding network and access a diverse pool of investors through our platform.",
    details: [
      "Connect with investors interested in various sectors relevant to your project.",
      "Utilize built-in tools to pitch your project and schedule meetings with investors.",
    ],
    image: "investor_network",
    CallToAction: "Get Started",
    route: "/myprofile?value=project-proposal",
  },
  
];

export const MaterialFeatures = [
  {
    title: "Always Up-to-Date Material Pricing",
    description:
      "Plan your budget with confidence. Get real-time material cost updates based on market trends.",
    details: [
      "Automatic price adjustments reflect current availability.",
      "Set price alerts to stay informed of cost fluctuations.",
    ],
    image: "material_pricing",
    CallToAction: "Get Started",
    route: "/",
  },
  {
    title: "Find Reliable Suppliers - Fast",
    description:
      "Make informed decisions with supplier reviews and ratings based on real user experiences.",
    details: [
      "Access user-generated reviews and ratings for suppliers worldwide.",
      "View detailed supplier performance metrics and quality scores.",
    ],
    image: "supplier_reviews",
  },
  {
    title: "Make Smarter Material Choices",
    description:
      "Compare different materials side-by-side to find the perfect fit for your project.",
    details: [
      "Easily compare durability, cost, and environmental impact of materials.",
      "Filter by project needs and sustainability criteria for informed decisions.",
    ],
    image: "material_comparison",
    CallToAction: "Get Started",
    route: "/",
  },
  {
    title: "Build Green - Sustainable Options at your Fingertips",
    description:
      "Support sustainable building practices with our directory of materials with environmental certifications.",
    details: [
      "Find materials with certifications like LEED, Green Seal, and ENERGY STAR.",
      "Choose eco-friendly materials for a greener future.",
    ],
    image: "sustainable_materials",
    CallToAction: "Get Started",
    route: "/",
  },
];

export const CollaborationFeatures = [
  {
    title: "Effortless Project Management",
    description:
      "Stay on top of projects, teams, and deadlines with our comprehensive project management tools.",
    details: [
      "Assign tasks, track progress, and set clear deadlines for your team.",
      "Integrate with other tools to streamline your workflow.",
    ],
    image: "project_management",
    CallToAction: "Get Started",
    route: "/",
  },
  {
    title: "Seamless Communication & Collaboration",
    description:
      "Break down communication silos and foster collaboration across teams with our communication platform.",
    details: [
      "Chat, video conference, and edit documents together in real-time.",
      "Integrates with email and other tools for easy access.",
    ],
    image: "communication_platform",
    CallToAction: "Get Started",
    route: "/",
  },
  {
    title: "Secure File Sharing & Storage",
    description:
      "Store and share files securely within the platform, ensuring data integrity and easy access for your team.",
    details: [
      "Benefit from encrypted file storage and sharing options.",
      "Handle large files and organize documents by project or team.",
    ],
    image: "file_sharing",
    CallToAction: "Get Started",
    route: "/",
  },
  {
    title: "Real-time Collaboration Made Easy",
    description:
      "Work together seamlessly and improve project outcomes with our real-time collaboration features.",
    details: [
      "Multiple users can work on documents simultaneously.",
      "Get live updates and stay on the same page with your team.",
    ],
    image: "real_time_collaboration",
    CallToAction: "Get Started",
    route: "/",
  },
];

export const propertyManagementServices = [
  {
    title: "Rental Management",
    description:
      "Comprehensive rental management services to ensure a smooth and efficient property rental process.",
    details: [
      "Tenant screening and selection",
      "Lease preparation and execution",
      "Rent collection and deposit handling",
      "Eviction management (if necessary)",
    ],
    image: tenantScreening,
    CallToAction: "Learn More",
    route: "/rental-management",
  },
  {
    title: "Maintenance & Repairs",
    description:
      "Expert maintenance and repair services to keep your property in top condition.",
    details: [
      "Routine maintenance coordination (e.g., HVAC, plumbing)",
      "Overseeing repairs and renovations",
      "Vendor management and communication",
    ],
    image: maintenance,
    CallToAction: "Learn More",
    route: "/maintenance-repairs",
  },
  {
    title: "Financial Management",
    description:
      "Professional financial management services to help you stay on top of your property’s finances.",
    details: [
      "Monthly financial statements and reports",
      "Managing maintenance and repair budgets",
      "Overseeing property taxes and insurance",
    ],
    image: financialStatements,
    CallToAction: "Learn More",
    route: "/financial-management",
  },
  {
    title: "Communication & Reporting",
    description:
      "Regular communication and detailed reporting to keep you informed about your property.",
    details: [
      "Regular communication with owners on property status",
      "24/7 emergency contact availability",
      "Online portal access for owners and tenants",
    ],
    image: communication,
    CallToAction: "Learn More",
    route: "/communication-reporting",
  },
];