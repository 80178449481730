export const faqData = [
  {
    question: "What is the Built Environment Value Chain (BEVC) platform?",
    answer:
      "The BEVC platform is a unified solution designed to revolutionize the way stakeholders within the Built Environment Value Chain interact. It aims to address the challenges of fragmentation and lack of collaboration among architects, engineers, contractors, suppliers, and property managers.",
  },
  {
    question: "What are the key components of the BEVC platform?",
    answer:
      "The key components include: 1. Unified Collaboration Hub: A centralized platform for seamless interaction and collaboration among professionals. 2. Comprehensive Job Portal: Facilitates job search and engagement for professionals. 3. Materials Database: Offers a vast database of materials with real-time pricing information. 4. Property Search Engine: Simplifies the property discovery process. 5. Financing Access Center: Streamlines access to financing options.",
  },
  {
    question: "How does the Unified Collaboration Hub work?",
    answer:
      "The Unified Collaboration Hub connects professionals across disciplines, fostering collaboration and promoting knowledge sharing. It provides a centralized platform for communication, file sharing, and project management.",
  },
  {
    question: "What benefits does the Job Portal offer?",
    answer:
      "The Job Portal streamlines the search for new opportunities, addressing the challenges professionals face in finding suitable job engagements. It offers a user-friendly interface for job posting, application, and tracking.",
  },
  {
    question: "How comprehensive is the Materials Database?",
    answer:
      "The Materials Database offers a robust collection of materials with real-time pricing information, simplifying the sourcing process for professionals. It provides detailed specifications, reviews, and comparisons to aid in decision-making.",
  },
  {
    question: "What features does the Property Search Engine offer?",
    answer:
      "The Property Search Engine provides an advanced search functionality for property discovery, offering filters for location, price range, amenities, and more. It aggregates listings from various sources to provide a comprehensive database.",
  },
  {
    question:
      "How does the Financing Access Center simplify access to funding?",
    answer:
      "The Financing Access Center streamlines the process of securing funding for both professionals and individuals. It offers a curated list of financing options, including loans, grants, and investment opportunities, tailored to the needs of users.",
  },
  {
    question: "Who are the target users of the BEVC platform?",
    answer:
      "The target users include architects, engineers, contractors, suppliers, property managers, and individuals seeking properties or financing options within the Built Environment Value Chain.",
  },
]
