<!-- src/components/WhatsAppEmbed.vue -->
<template>
  <div>
    <button @click="loadWhatsApp" class="whatsapp-button">
      Chat with us on WhatsApp
    </button>
    <div class="iframe-container" v-if="iframeSrc">
      <iframe :src="iframeSrc" frameborder="0" class="whatsapp-iframe"></iframe>
    </div>
  </div>
</template>

<script>
export default {
  name: 'WhatsAppEmbed',
  props: {
    phoneNumber: {
      type: String,
      required: true,
    },
    message: {
      type: String,
      default: '',
    },
  },
  data() {
    return {
      iframeSrc: null,
    };
  },
  methods: {
    loadWhatsApp() {
      const baseUrl = 'https://api.whatsapp.com/send';
      const phoneQuery = `phone=${this.phoneNumber}`;
      const textQuery = this.message ? `&text=${encodeURIComponent(this.message)}` : '';
      this.iframeSrc = `${baseUrl}/?${phoneQuery}${textQuery}&type=phone_number&app_absent=0`;
    },
  },
};
</script>

<style>
.whatsapp-button {
  display: flex;
  align-items: center;
  text-decoration: none;
  color: #25d366;
  font-weight: bold;
  cursor: pointer;
  background-color: #e0e0e0;
  border: none;
  padding: 10px 20px;
  border-radius: 5px;
  transition: background-color 0.3s ease;
}
.whatsapp-button:hover {
  background-color: #d0d0d0;
}
.iframe-container {
  position: relative;
  width: 100%;
  height: 100vh;
  margin-top: 20px;
}
.whatsapp-iframe {
  width: 100%;
  height: 100%;
  border: none;
}
</style>
