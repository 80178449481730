<template>
    <div class="search-component">
        <p v-if="searchQuery === ''">{{ `Search......${searchFields}` }}</p>
        <input type="search" v-model="searchQuery" @click="resetInput" @input="handleInput" placeholder="Search..."
            class="search-input" />
        <div class="other-actions">
            <button @click="$router.push('/buy')" v-if="route === '/property'">
                More Properties
            </button>
            <button @click="postProperty" v-if="route === '/property'">
                List Your Property
            </button>
            <button @click="$router.push('/jobsearch/')" v-if="route === '/jobs'">More Jobs</button>
            <button @click="postProperty" v-if="route === '/jobs'">Post a Job</button>
            <button @click="$router.push('/materialsearch')" v-if="route === '/materials'">Find a
                Building Materials</button>
            <button @click="$router.push('/supplier')" v-if="route === '/materials'">
                Sell Materials</button>
            <button @click="$router.push('/myprofile?value=consultants')" v-if="route === '/construction'">Find a
                Consultant</button>
        </div>

    </div>
</template>

<script>
import { ref, watch, defineComponent } from 'vue';
import { useRouter } from 'vue-router';

export default defineComponent({
    name: 'SearchComponent',
    props: {
        searchObject: {
            type: Array,
            default: () => []
        },
        searchFields: {
            type: Array,
            default: () => ['id', 'city', 'property_features.description', 'property_images.description']
        },
        route: {
            type: String,
            required: true
        }
    },


    emits: ['update-results'],
    setup(props, { emit }) {
        const searchQuery = ref('');
        const router = useRouter();


        const postProperty = () => {
            if (router.currentRoute.value.path === '/property') {
                router.push('/myprofile?value=list-property');
            }
            if (router.currentRoute.value.path === '/jobs') {
                router.push('/myprofile?value=hire-manpower');
            }

        }


        function searchNestedObject(obj, searchTerm) {
            if (!obj) {
                return false;
            }

            for (const key in obj) {
                if (typeof obj[key] === 'object') {
                    if (searchNestedObject(obj[key], searchTerm)) {
                        return true;
                    }
                } else if (typeof obj[key] === 'string' && obj[key].toLowerCase().includes(searchTerm)) {
                    return true;
                }
            }
            return false;
        }

        const handleInput = () => {
            if (searchQuery.value.trim()) {
                const query = searchQuery.value.toLowerCase();
                const filteredResults = props.searchObject.filter(item => searchNestedObject(item, query));
                emit('update-results', filteredResults);
            }
        };

        // function searchItem(item, query) {
        //     return props.searchFields.some(field => {
        //         const value = field.split('.').reduce((acc, key) => acc?.[key], item);
        //         console.log(value)
        //         // If the field is an array, search within each object in the array
        //         if (Array.isArray(value)) {
        //             return value.some(innerItem => searchItem(innerItem, query));
        //         }
        //         // Check if the field contains the search query
        //         return value && value.toString().toLowerCase().includes(query);
        //     });
        // }



        const resetInput = () => {
            searchQuery.value = '';
            const filteredResults = [];
            emit('update-results', filteredResults);
        };

        watch(() => searchQuery.value, () => {
            handleInput(); // Reapply search on new data
        }, { deep: true });

        return {
            searchQuery,
            handleInput,
            resetInput,
            postProperty,
        };
    }
});
</script>

<style scoped></style>
