<template>
  <div class="tab-buttons">
    <button v-for="functionGroup in functionGroups" :key="functionGroup.group"
      @click="setActiveCallToAction(functionGroup.id)">{{ functionGroup.group }}</button>
  </div>




  <div class="home">
    <ul class="call-to-action">
      <li v-for="item in callToAction" :key="item.name" class="card">
        <h3>{{ item.name }}<i :class="item.icon"></i></h3>
        <p>{{ item.value_proposition }}</p>
        <a :href="item.call_to_action_path">{{ item.call_to_action_text }}</a>
      </li>
    </ul>
  </div>



  <div class="home">

    <FeaturesPage />

  </div>



  <div class="home">
    <!-- <BuildHouse /> -->

    <ConstructionHero />

  </div>

  <div class="home">
    <FundingHero />
  </div>

  <div class="faq-page">
    <FaqPage />
  </div>
</template>

<script>
// @ is an alias to /src
import { ref, onMounted, watch } from 'vue'
// import PropertyListings from './property/PropertyListings.vue'
// import BuildHouse from './constructions/BuildHouse.vue'
// import FundingOptionsPage from './financing/FundingOptionsPage.vue'
// import JobsSearchPage from './jobs/JobsSearchPage.vue'
// import MaterialsPage from '@/views/constructions/MaterialsPage.vue'
import axios from '@/utils/axios'
import FaqPage from '@/views/Faq/FagPage.vue'
import FeaturesPage from '@/views/Features/FeaturesPage.vue'



export default {
  name: 'HomeView',
  components: {
    // PropertyListings,
    // BuildHouse,
    // FundingOptionsPage,
    // JobsSearchPage,
    // MaterialsPage,
   
    FaqPage,
    FeaturesPage,
  },
  setup() {

    const functionGroups = ref([]);
    const callToAction = ref([

    ])




    const getCallToAction = () => {
      axios
        .get('/subscriptions/value-propositions/', {
          params: {
            function: activeCallToAction.value
          }
        })
        .then((res) => {
          callToAction.value = res.data.results;
        })
        .catch((error) => {
          console.error(error);
        });
    };

    const functionsGroupsItem = ref([]);









    const callToActionConstruction = ref([
      {
        title: 'Build Your Dream Home',
        description: 'Discover the perfect construction solutions to bring your dream home to life.',
        link: 'https://example.com/build-dream-home',
        link_text: 'Start Building',
        icon: 'bi bi-tools'
      },
      {
        title: 'Construction Services',
        description: 'Get professional construction services for your projects with our expert team.',
        link: 'https://example.com/construction-services',
        link_text: 'Get Services',
        icon: 'bi bi-hammer'
      },
      {
        title: 'Invest in Construction Projects',
        description: 'Explore investment opportunities in construction projects for potential growth.',
        link: 'https://example.com/invest-construction',
        link_text: 'Invest Now',
        icon: 'bi bi-bank'
      },
    ])

    const getFunctionsGroups = () => {
      axios
        .get('/subscriptions/functions/')
        .then((res) => {
          functionGroups.value = res.data.results
        })
        .catch((err) => {
          console.log(err)
        })
    }


    const getFunctionsGroupsByActiveCallToAaction = () => {
      axios
        .get('/subscriptions/functions/')
        .then((res) => {
          functionsGroupsItem.value = res.data.results
        })
        .catch((err) => {
          console.log(err)
        })
    }

    const propertylist = ref([])


    const getPropertyList = () => {
      axios
        .get('/property/propertiesdetails/')
        .then((res) => {
          propertylist.value = res.data.results
        })
        .catch((err) => {
          console.log(err)
        })
    }

    getPropertyList()


    const activeCallToAction = ref('Material Sourcing')

    const setActiveCallToAction = (callToAction) => {
      activeCallToAction.value = callToAction
    }

    watch(activeCallToAction, () => {
      getFunctionsGroupsByActiveCallToAaction()
      getCallToAction();
    })

    onMounted(() => {
      getFunctionsGroups()
    })

    return {
      callToAction,
      activeCallToAction,
      setActiveCallToAction,
      callToActionConstruction,
      propertylist,
      getPropertyList,
      filterpropertylist: propertylist,
      functionGroups,
      getFunctionsGroups,
      getFunctionsGroupsByActiveCallToAaction,
      functionsGroupsItem,
      getCallToAction,
    }
  }
}
</script>
