import axios from 'axios';

// Function to get the base URL based on the hostname
const getBaseUrl = () => {
  const hostname = window.location.hostname;
  console.log ('hostname', hostname);

  switch (hostname) {
    case 'app.bevcglobal.com':
    case 'web.bevcglobal.com':
      return 'https://backend.bevcglobal.com/api/';
    case 'app.paanguzo.com':
    case 'web.paanguzo.com':
      return 'https://backend.paanguzo.com/api/';
    default:
      return 'http://127.0.0.1:8000/api/';
  }
};

// List of routes where headers should be disabled
const routesWithoutHeaders = [
  '/accounts/signup/verify/?code=',
  '/accounts/login',
  '/accounts/signup/',
  '/accounts/password/reset/',
  '/accounts/password/reset/confirm/',
  '/subscriptions/simulate-mpesa/',
  'https://backend.thinkiqbs.com/api/socials/whatsapp-messages/',
];

// Create an axios instance with base settings
const instance = axios.create ({
  baseURL: getBaseUrl (),
  withCredentials: true, // Include credentials (cookies) in CORS requests
});

// Request interceptor to handle headers
instance.interceptors.request.use (
  config => {
    // Check if the route should disable headers
    const disableHeaders = routesWithoutHeaders.some (route =>
      config.url.includes (route)
    );

    if (!disableHeaders) {
      // Automatically includes CSRF token from the cookie
      const webToken = localStorage.getItem ('webToken');
      if (webToken) {
        config.headers.Authorization = `Token ${webToken}`;
      }
    }

    return config;
  },
  error => Promise.reject (error) // Handle request error
);

// Response interceptor to handle 401 Unauthorized and remove token
instance.interceptors.response.use (
  response => response,
  error => {
    if (error.response && error.response.status === 401) {
      // Unauthorized, remove the token from local storage
      localStorage.removeItem ('webToken');
      console.log ('Token invalid or expired, removed from memory');
      // Optionally, redirect to login or show an alert
      // window.location.href = '/login';
    }
    return Promise.reject (error);
  }
);

export default instance;
