import { createApp } from "vue";
import { createPinia } from "pinia";
import App from "./App.vue";
import router from "./router";
import "./assets/style.css";
import "bootstrap-icons/font/bootstrap-icons.css";
import VueTelInput from "vue-tel-input";
import "vue-tel-input/vue-tel-input.css";
import vue3GoogleLogin from 'vue3-google-login';





const pinia = createPinia();

const globalOptions = {
  mode: "auto",
};

const addCommaSeparator = (value) =>
  value.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");

const app = createApp(App);



app.use (vue3GoogleLogin, {
  clientId: '932031693579-8ek3ouqdurk13gj2lrds7c867g8gbscv.apps.googleusercontent.com',
});







// Add Pinia before other plugins
app.use(pinia);

// Phone Input Component


// Add other plugins
app.use(VueTelInput, globalOptions);
app.use(router);

// Add mixins and mount the app
app.mixin({ methods: { addCommaSeparator } });
app.mount("#app");
