<!-- src/components/WhatsAppButton.vue -->
<template>
  <a
    :href="whatsAppLink"
    target="_blank"
    rel="noopener noreferrer"
    class="whatsapp-button"
  >
    <img src="path/to/whatsapp-icon.png" alt="WhatsApp" />
    Chat with us on WhatsApp
  </a>
</template>

<script>
export default {
  name: 'WhatsAppButton',
  props: {
    phoneNumber: {
      type: String,
      required: true,
    },
    message: {
      type: String,
      default: '',
    },
  },
  computed: {
    whatsAppLink() {
      const baseUrl = 'https://api.whatsapp.com/send';
      const phoneQuery = `phone=${this.phoneNumber}`;
      const textQuery = this.message ? `&text=${encodeURIComponent(this.message)}` : '';
      return `${baseUrl}/?${phoneQuery}${textQuery}&type=phone_number&app_absent=0`;
    },
  },
};
</script>

<style>
.whatsapp-button {
  display: flex;
  align-items: center;
  text-decoration: none;
  color: #25d366;
  font-weight: bold;
}
.whatsapp-button img {
  margin-right: 10px;
}
</style>
